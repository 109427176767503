<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data () {
    return {
      str: window.location.href
    }
  },
  created () {
    this.changeFavicon()
  },
  methods: {
    changeFavicon () {
      // console.log('this.str :>> ', this.str);
      // //请求配置 判断是否为空气平台

      let favicon = document.querySelector('link[rel="icon"]')
      let href = null
      if (this.str.indexOf('air') !== -1) {
        href = 'faviconair.ico'
      } else if (this.str.indexOf('smoke') !== -1) {
        href = '/faviconsmoke.ico'
      } else if (this.str.indexOf('water') !== -1) {
        href = 'faviconwater.ico'
      } else if (this.str.indexOf('infant') !== -1) {
        href = 'faviconinfant.ico'
      } else {
        href = '/favicon.ico'
      }
      //       // console.log('app', favicon)
      if (favicon !== null) {
        favicon.href = href
        // favicon.href = this.appType == 'dr' ? '/favicon.ico' : '/faviconkb.ico'
      } else {
        favicon = document.createElement('link')
        favicon.rel = 'icon'
        favicon.href = href
        document.head.appendChild(favicon)
      }
    }
  }
}
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  font-size: 0.875rem;
}
</style>
