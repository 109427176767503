import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // 省级大屏
  {
    path: '/city',
    name: 'City',
    component: () => import('../views/city')
  },
  // 区级大屏 萧山版本
  {
    path: '/area',
    name: 'Area',
    component: () => import('../views/area')
  },
  // 区级大屏 
  {
    path: '/district',
    name: 'District',
    component: () => import('../views/district')
  },
  {
    path: '/newDistrict',
    name: 'newDistrict',
    component: () => import('../views/newDistrict')
  },
  // 酒店大屏大屏
  {
    path: '/hotel',
    name: 'Hotel',
    component: () => import('../views/hotel')
  },
  // 空气大屏
  {
    path: '/air',
    name: 'Air',
    component: () => import('../views/air')
  },
  // 控烟大屏
  {
    path: '/smoke',
    name: 'Smoke',
    component: () => import('../views/smoke')
  },
  // 项目关闭展示页面
  {
    path: '/maintain',
    name: 'maintain',
    component: () => import('../views/maintain')
  },
  // 直饮水大屏
  {
    path: '/water',
    name: 'Water',
    component: () => import('../views/water/index.vue')
  },
  // 婴幼儿大屏
  {
    path: '/infant',
    name: 'Infant',
    component: () => import('../views/infant')
  },
  // 婴幼儿电视展示
  {
    path: '/infanttv',
    name: 'InfantTv',
    component: () => import('../views/infantTv')
  },
  // 婴幼儿电视登录
  {
    path: '/infantlogin',
    name: 'InfantLogin',
    component: () => import('../views/infantTv/login.vue')
  },
  {
    path: '/',
    name: 'Chooserouter',
    component: () => import('../views/chooseRouter/index.vue')
  }
]

const router = new VueRouter({
  mode: 'history', // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes
})

export default router
